<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">{{ smsTemplate.name }}/Update</h4>

            </div>
        
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div :class="code.length>0 ? 'col-md-6' : 'col-md-12'">
                                <h5 class="py-1 text-center">Write your template </h5>
                                <div>
                                    <prism-editor class="my-editor" v-model="code" :highlight="highlighter" line-numbers></prism-editor>
                                </div>
                                <div class="py-3 d-flex justify-content-end">
                                    <button class="btn btn-primary" @click="updateSmsTemplate()">Update</button>
                                </div>
                            </div>

                            <div class="col-md-6 " v-if="code.length">
                                <h5 class="py-1 text-center">Template Preview </h5>
                                <div style="height: auto;" class="bg-secondary p-2">
                                  <div class="bg-white p-2">
                                    <div v-html="code">
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </template>
</AppLayout>
</template>

<script>
import {
    PrismEditor
} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import {
    highlight,
    languages
} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

// components
import AppLayout from "@/layouts/backEnd/AppLayout";

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "EmailTemplateList",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        PrismEditor,

        AppLayout,

    },

    data() {
        return {
            code: '',
            lineNumbers: true,

        };
    },
    computed: {
        ...mapGetters({

            smsTemplate: "appSmsTemplates/smsTemplate",

        }),

    },
    watch: {

    },
    methods: {
        ...mapActions({

            getSmsTemplate: "appSmsTemplates/getSmsTemplate",
            putSmsTemplate: "appSmsTemplates/putSmsTemplate",
        }),

        highlighter(code) {
            return highlight(code, languages.js); // languages.<insert language> to return html with markup
        },

        async getSmsTemplateHandler(id) {
            this.loader(true);
            const response = await this.getSmsTemplate({
                id
            });
            this.loader(false);
            if (response ?.message) {
                this.showToastMessage(response);
            }
            console.log(response);
            if (response ?.status == 200 && this.smsTemplate ?.id) {
                this.code = this.smsTemplate ?.template;
            }

        },
        async updateSmsTemplate() {
            if (this.code.length === 0) {
                this.showToastMessage({
                    type: 'error',
                    message: 'Please write your template'
                });
                return;
            }
            this.loader(true);
            const response = await this.putSmsTemplate({
                id: this.smsTemplate ?.id,
                data: {
                    template: this.code
                }
            });
            this.loader(false);
            if (response ?.message) {
                this.showToastMessage(response);
            }
            console.log(response);
            if (response ?.status == 200 ) {
                this.showToastMessage({
                    type: 'success',
                    message: 'Sms template updated successfully'
                });
                this.$router.push({
                    name: 'smsTemplates'
                });
            }
        },
    },

    async mounted() {
        console.log('adsf');
        await this.getSmsTemplateHandler(this.$route.params.id);
      
    },
};
</script>

<style>
.my-editor {
    background: #2d2d2d;
    color: white !important;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
}

.prism-editor-wrapper {
    height: auto;
}

.prism-editor__editor {
    color: white;
}

.prism-editor__textarea:focus {
    outline: none;
}

.height-300 {
    height: 300px;
}
</style>
